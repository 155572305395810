import { Accordion, H4, Paragraph, Square, XStack, YStack } from '@my/ui'
import { ChevronDown } from '@tamagui/lucide-icons'
import { useTranslation } from 'react-i18next'

export const FAQScreen = () => {
  const [t] = useTranslation('landingScreen', { keyPrefix: 'faqScreen' })

  return (
    <div className="mx-auto mt-16 max-w-[80rem] sm:mt-32">
      <YStack position="relative" id="faq" />
      <YStack ai="center" mt="$12" $sm={{ mt: '$8' }}>
        <H4 size="$10" $sm={{ size: '$9' }} ta="center">
          {t('title')}
        </H4>
        <Accordion type="multiple" w="80%" mt="$12" $sm={{ mt: '$6' }}>
          {(t('items', { returnObjects: true }) as { label: string; desc: string }[]).map(
            ({ label, desc }) => (
              <Accordion.Item key={label} value={label}>
                <Accordion.Trigger>
                  {({ open }) => (
                    <XStack jc="space-between">
                      <Paragraph>{label}</Paragraph>
                      <Square animation="quick" rotate={open ? '180deg' : '0deg'}>
                        <ChevronDown size="$1" />
                      </Square>
                    </XStack>
                  )}
                </Accordion.Trigger>
                <Accordion.Content>
                  <Paragraph theme="alt1">{desc}</Paragraph>
                </Accordion.Content>
              </Accordion.Item>
            )
          )}
        </Accordion>
      </YStack>
    </div>
  )
}
