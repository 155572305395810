import { LandingScreen } from 'app/features/landing/LandingScreen/LandingScreen'
import { NextSeo } from 'next-seo'

export const Page = () => {
  return (
    <>
      <NextSeo
        openGraph={{
          title: 'MyDreamBoy AI - Pick your AI boyfriend | Meet Your Dream Boy',
          url: 'https://www.mydreamboy.com/',
          description:
            'MyDreamBoy is the best AI Porn Generator for Male. Our cutting-edge AI Porn Generator offers a seamless way to create highly realistic AI-generated pornographic art and images, featuring hundreds of NSFW/SFW tags for personalized content creation.',
          images: [
            {
              url: 'https://api.mydreamboy.com/storage/v1/object/public/static/x_1600x900.png',
            },
          ],
        }}
        twitter={{
          cardType: 'summary_large_image',
          site: '@MyDreamBoyAI',
        }}
      />
      <LandingScreen />
    </>
  )
}

export default Page
