import { BentoCard, BentoGrid, Marquee } from '@my/magic-ui'
import { YStack } from '@my/ui/src'
import { getExploreModelLink, getExplorePostLink } from 'app/utils/model-version'
import { Sparkles, MessageCircleMore, ImagePlus } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnimatedListDemo } from './components/AnimatedListDemo'

const ModelsImages = [
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/30d96c63-c700-4952-b7a6-080448316a75/20240703001146_1719936706.6124976_idx0.jpg',
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/8da96852-2900-472c-96c4-b3e01d20d06b/20240706012439_1720200279.248542_idx0.jpg',
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/a8c1e501-e16e-4e4a-a14c-70f8bcdb91b5/20240619081923_1718756363.4889128_idx0.jpg',
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/d3c661f9-9b50-48bd-b925-21a2b4dc8e03/20240621214623_1718977583.261883_idx0.jpg',
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/762f5d6d-033c-44ae-95ef-1bfd3086c1bd/20240630171057_1719738657.3903995_idx0.jpg',
  'https://alg.mydreamboy.com/storage/v1/object/public/gen_imgs/gen_imgs_in_models_nsfw/e290f98c-ad41-410b-8a50-b185a375935e/20240630070900_1719702540.1802332_idx0.jpg',
]

export const FeatureSection = () => {
  const { t } = useTranslation('landingScreen', { keyPrefix: 'features' })

  const features = useMemo(
    () => [
      {
        Icon: Sparkles,
        name: t('create.title'),
        description: t('create.desc'),
        href: getExploreModelLink(),
        cta: t('explore'),
        className: 'col-span-3 lg:col-span-1',
        background: (
          <Marquee
            pauseOnHover
            className="absolute top-10 [--duration:20s] [mask-image:linear-gradient(to_top,transparent_40%,#000_100%)] "
          >
            {ModelsImages.map((f, idx) => (
              <img src={f} key={idx} width={160} height={80} />
            ))}
          </Marquee>
        ),
      },
      {
        Icon: MessageCircleMore,
        name: t('chat.title'),
        description: t('chat.desc'),
        href: '/chat',
        cta: t('explore'),
        className: 'col-span-3 lg:col-span-2',
        background: (
          <AnimatedListDemo className="absolute right-2 top-4 h-[300px] w-[600px] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105" />
        ),
      },
      {
        Icon: ImagePlus,
        name: t('play.title'),
        description: t('play.desc'),
        href: getExplorePostLink(),
        cta: t('explore'),
        className: 'col-span-3 lg:col-span-3 h-[500px]',
        background: (
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/feature_tag_dashboard.png"
            className="absolute right-2 top-4  w-[800px] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105"
          />
        ),
      },
    ],
    [t]
  )
  return (
    <div className="mx-auto max-w-[80rem] px-6 md:px-8 pt-16 pb-56">
      <YStack position="relative" id="features" />
      <BentoGrid>
        {features.map((feature, idx) => (
          <BentoCard key={idx} {...feature} />
        ))}
      </BentoGrid>
    </div>
  )
}
