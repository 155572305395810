import { H4, SizableText, XStack, YStack } from '@my/ui'
import { Lock } from '@tamagui/lucide-icons'
import { useTranslation } from 'react-i18next'

export const PrivateScreen = () => {
  const [t] = useTranslation('landingScreen', { keyPrefix: 'privateScreen' })
  return (
    <YStack ai="center" mt="$9" gap="$8" $sm={{ px: '$5' }}>
      <XStack ai="center" gap="$4">
        <Lock size="$4" />
        <H4 size="$10" ai="center" ta="center" $sm={{ size: '$9' }}>
          {t('title')}
        </H4>
      </XStack>
      <SizableText w={800} ta="center" size="$5" color="$gray9" $sm={{ w: '100%', size: '$4' }}>
        {t('desc')}
      </SizableText>
    </YStack>
  )
}
