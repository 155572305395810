'use client'

import { BorderBeam, Button, TextShimmer } from '@my/magic-ui'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import { getExplorePostLink } from 'app/utils/model-version'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'solito/router'

const underlinedWord = (text: string) => (
  <span className="cursor-pointer underline decoration-yellow-300 decoration-wavy"> {text}</span>
)

export default function HeroSection() {
  const { t } = useTranslation('landingScreen')
  const ref = useRef(null)
  const inView = useInView(ref, { once: true, margin: '-100px' })
  const router = useRouter()
  return (
    <section id="hero" className="relative mx-auto mt-32 max-w-[80rem] px-6 text-center md:px-8">
      <div
        className="backdrop-filter-[12px] inline-flex h-7 items-center justify-between rounded-full border border-white/5 bg-white/10 px-3 text-xs text-white dark:text-black transition-all ease-in hover:cursor-pointer hover:bg-white/20 group gap-1 translate-y-[-1rem] animate-fade-in opacity-0"
        onClick={() => router.push('/model/generator')}
      >
        <TextShimmer className="inline-flex items-center justify-center">
          <span>✨ New Create Persona Feature</span>
          <ArrowRightIcon className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
        </TextShimmer>
      </div>
      <div className="flex justify-between items-center animate-fade-in opacity-0 [--animation-delay:200ms] py-6">
        <h1 className="f-1 bg-gradient-to-br dark:from-white from-black from-30% dark:to-white/40 to-black/40 bg-clip-text  text-4xl font-medium leading-none tracking-tighter text-transparent text-balance 	sm:text-5xl md:text-7xl xl:text-8xl translate-y-[-1rem] text-left">
          MyDreamBoy AI Porn Generator <br className="hidden md:block" />
          for Male.
        </h1>
        <img
          src="https://api.mydreamboy.com/storage/v1/object/public/static/web_hero_model.png"
          className="w-1/2 hidden lg:block"
        />
        <img
          src="https://api.mydreamboy.com/storage/v1/object/public/static/mobile_hero_model.png"
          className="w-1/2 block lg:hidden shrink-0 -ml-10"
        />
      </div>
      <p className="mb-12 text-lg tracking-tight text-gray-400 md:text-xl text-balance translate-y-[-1rem] animate-fade-in opacity-0 [--animation-delay:400ms]">
        {t('heroDesc.text1')} {underlinedWord(t('heroDesc.keyword1'))},
        <br className="hidden md:block" /> {t('heroDesc.text2')}{' '}
        {underlinedWord(t('heroDesc.keyword2'))}, {t('heroDesc.text3')}{' '}
        {underlinedWord(t('heroDesc.keyword3'))}.
      </p>
      <Button
        size="lg"
        className="translate-y-[-1rem] animate-fade-in gap-1 rounded-lg text-white dark:text-black opacity-0 ease-in-out [--animation-delay:600ms]"
        onClick={() => router.push(getExplorePostLink())}
      >
        <span>{t('start')} </span>
        <ArrowRightIcon className="ml-1 size-4 transition-transform duration-300 ease-in-out group-hover:translate-x-1" />
      </Button>
      <div
        ref={ref}
        className="relative mt-[8rem] animate-fade-up opacity-0 [--animation-delay:400ms] [perspective:2000px] after:absolute after:inset-0 after:z-50 after:[background:linear-gradient(to_top,black_30%,transparent)]"
      >
        <div
          className={`rounded-xl border border-white/10 bg-white bg-opacity-[0.01] before:absolute before:bottom-1/2 before:left-0 before:top-0 before:h-full before:w-full before:opacity-0 before:[filter:blur(180px)] before:[background-image:linear-gradient(to_bottom,var(--color-one),var(--color-one),transparent_40%)] ${
            inView ? 'before:animate-image-glow' : ''
          }`}
        >
          <BorderBeam
            size={200}
            duration={12}
            delay={11}
            colorFrom="var(--color-one)"
            colorTo="var(--color-two)"
          />

          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/hero.png"
            alt="Hero Image"
            className="relative w-full h-full rounded-[inherit] border object-contain"
          />
        </div>
      </div>
    </section>
  )
}
